import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlueButton from "../components/blueButton"
import { Link } from "gatsby"

function Success() {
  return (
    <Layout>
      <Seo title="Success" />
      <section className="w-full py-32  min-h-screen bg-homepage-header bg-cover bg-no-repeat grid place-items-center">
        <div className="bg-dark-200 max-w-5xl mx-auto w-10/12 p-16 flex flex-col space-y-8 justify-center items-center">
          <h1 className="text-3xl lg:text-5xl font-bold text-white">
            Form was submitted successfully!
          </h1>
          <div>
            <Link to="/">
              <BlueButton>GO BACK HOME</BlueButton>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Success
